import { USER_ROLES } from "@milize/common/constants";
import { loggedInUserSelector } from "features/auth/selectors";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { StyledAvatar } from "./styles";

const Avatar = () => {
  const loggedUser = useSelector(loggedInUserSelector);
  const { formatMessage: t } = useIntl();
  const roleUser = process.env.REACT_APP_SITE;
  return (
    <StyledAvatar>
      {roleUser === USER_ROLES.OPERATOR && (
        <span className="role-name">{t({ id: "role.userOp.text" })}</span>
      )}
      <span className="user-name">{loggedUser?.name || ""}</span>
    </StyledAvatar>
  );
};

export default Avatar;
