import { SelectProps } from "antd";
import { FormikFieldProps } from "formik-antd/lib/FieldProps";
import { StyledSelect } from "./styles";
import IC_ARROW from "assets/images/ic_arrow_down.svg";

export type ISelectProps<T = any> = FormikFieldProps & SelectProps<T>;

const Select = (props: ISelectProps) => {
  return (
    <StyledSelect
      {...props}
      suffixIcon={<img src={IC_ARROW} alt="ic-arrow" />}
    />
  );
};

export default Select;
