import { getToken } from "@milize/common/utils/token";
import { loginSuccess } from "features/auth/slice";
import feathers from "libs/feathers";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export type ReceivedProps = Record<string, any>;

const useReAuthenticate = (props: ReceivedProps) => {
  const [loading, setLoading] = useState<boolean>(!!getToken());
  const dispatch = useDispatch();
  useEffect(() => {
    if (!getToken()) {
      setLoading(false);
      return;
    }

    const request = async () => {
      try {
        const { user } = await feathers.reAuthenticate();
        dispatch(loginSuccess({ user }));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    request();
  }, [dispatch]);

  return {
    ...props,
    loading,
  };
};

export type Props = ReturnType<typeof useReAuthenticate>;

export default useReAuthenticate;
