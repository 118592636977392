import { Comment } from "@milize/common/types";
import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
  list: Comment[];
};

const initialState: InitialState = {
  list: [],
};

const commentSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {},
});

export default commentSlice;
