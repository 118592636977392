import { Layout } from "antd";
import styled from "styled-components";

export const HeaderWrapper = styled(Layout.Header)`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 0 32px;
  line-height: normal;
  background-color: ${({ theme }) => theme.colors.gray["500"]};
  position: sticky;
  z-index: 99;
  top: 0;
  left: 0;

  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
  }
`;

export const StyledAvatar = styled("div")`
  display: flex;
  align-items: center;

  .user-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    white-space: nowrap;
    max-width: 600px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .role-name {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    min-width: 50px;
    height: 24px;
    border-radius: 100px;
    background-color: ${({ theme }) => theme.colors.yellow[200]};
    text-align: center;
    padding: 2px 15px 0 15px;
    color: ${({ theme }) => theme.colors.white.main};
    margin-right: 10px;
  }

  .user-img {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    margin-left: 12px;
    border: 1.5px solid ${({ theme }) => theme.colors.gray["500"]};
    box-shadow: 0 0 0 1.5px ${({ theme }) => theme.colors.gray["600"]};
  }
`;
