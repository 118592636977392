import { URLSearchParamsInit, useSearchParams } from "react-router-dom";
import { useMemo, useCallback } from "react";

type AppSearchParamsResult<T> = [T, SetSearchParams<T>];

type SetSearchParams<T> = (
  params: T,
  navigateOptions?: { replace?: boolean; state?: any }
) => void;

function useAppSearchParams<T extends Record<string, string>>(
  defaultInit?: URLSearchParamsInit
): AppSearchParamsResult<T> {
  const [searchParams, setSearchParams] = useSearchParams(defaultInit);

  const params = useMemo(
    () => Object.fromEntries(searchParams) as unknown as T,
    [searchParams]
  );

  const handleSetSearchParams = useCallback<SetSearchParams<T>>(
    (_params, navigateOptions) => {
      setSearchParams(Object.assign({}, params, _params), navigateOptions);
    },
    [params, setSearchParams]
  );

  return [params, handleSetSearchParams];
}
export default useAppSearchParams;
